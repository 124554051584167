import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { AuthService } from '@app/services/auth.service';
import { LoadingService } from '@app/services/loading.service';
import { ObjectToFormDataService } from '@app/services/object-to-form-data.service';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuditService {
  private apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private loadingService: LoadingService,
    private objectToFormDataConvertor: ObjectToFormDataService,
  ) {}

  getOrgFilteredData(body: any): Observable<any> {
    return this.http.post<any>(
      this.apiUrl + '/api/TrainingProgramRequest/GetTrainingProgramsList',
      body,
    );
  }

  getReceiptsList(body: any): Observable<any> {
    return this.http.post<any>(
      this.apiUrl + '/api/Invoices/GetInvoiceList',
      body,
    );
  }

  getTrainingProgramsData(body: any): Observable<any> {
    return this.http.post<any>(
      this.apiUrl + '/api/Dashboard/GetTrainingProgramsList',
      body,
    );
  }

  getApplicationData(id: number | string) {
    this.loadingService.startLoading();
    return this.http
      .get<any>(
        this.apiUrl +
          `/api/TrainingProgramRequest/GetTrainingOrganizationById?id=${id}`,
      )
      .pipe(finalize(() => this.loadingService.stopLoading()));
  }

  getFiltersData(lookup: number): Observable<any> {
    return this.http.get<any>(
      this.apiUrl + `/api/Lookup/GetLookups?lookupType=${lookup}`,
    );
  }

  private selectedRowDataSubject = new BehaviorSubject<any>(null);
  selectedRowData$ = this.selectedRowDataSubject.asObservable();

  setSelectedRowData(rowData: any) {
    this.selectedRowDataSubject.next(rowData);
  }

  getFile(fileId: number): Observable<any> {
    // Set the responseType to 'arraybuffer' to handle binary data
    return this.http
      .get(this.apiUrl + `/api/File/GetFileById?id=${fileId}`, {
        responseType: 'arraybuffer',
      })
      .pipe(
        map((response: ArrayBuffer) =>
          this.convertBytesToDataUrl(new Uint8Array(response)),
        ),
      );
  }

  private convertBytesToDataUrl(bytes: Uint8Array): string {
    // Convert UInt8Array to Blob
    const pdfBlob = new Blob([bytes], { type: 'application/pdf' });

    // Create a data URL from the Blob
    const dataUrl = URL.createObjectURL(pdfBlob);

    return dataUrl;
  }

  getCriteriaLookup(roleId: number) {
    return this.http.get<any>(
      this.apiUrl + `/api/Lookup/GetCriteriaLookupByRole?roleId=${roleId}`,
    );
  }

  saveAllEvaluations(body: any) {
    return this.http.post<any>(
      this.apiUrl +
        '/api/TrainingProgramRequest/CreateOrUpdateAllCriteriaWithNotes',
      body,
    );
  }

  saveCriteria(body: any) {
    return this.http.post<any>(
      this.apiUrl +
        '/api/TrainingProgramRequest/CreateOrUpdateCriteriaWithNotes',
      body,
    );
  }

  getExpertList() {
    return this.http.get<any>(
      this.apiUrl + '/api/Lookup/GetTechnicalAdvisorUsers',
    );
  }

  sendExpertData(body: any) {
    return this.http.post<any>(
      this.apiUrl +
        '/api/TrainingProgramRequest/AssignUserToTrainingOrganization',
      body,
    );
  }

  updateApplicationStatus(body: any) {
    return this.http.post<any>(
      this.apiUrl +
        '/api/TrainingProgramRequest/UpdateStatusTrainingOrganization',
      this.objectToFormDataConvertor.convert(body),
    );
  }

  updateApplicationNote(body: any) {
    return this.http.post<any>(
      this.apiUrl +
        '/api/TrainingProgramRequest/CreateOrUpdateTrainingProgramDocumentNote',
      body,
    );
  }

  sendInvoiceData(body: any) {
    return this.http.post<any>(
      this.apiUrl + '/api/Invoices/CreateOrUpdateTrainingOrganizationInvoice',
      this.objectToFormDataConvertor.convert(body),
    );
  }

  getInvoicesList(body: any) {
    return this.http.post<any>(
      this.apiUrl + '/api/Invoices/GetInvoiceList',
      body,
    );
  }

  getInvoiceData(id: number) {
    return this.http.get<any>(
      this.apiUrl + '/api/Invoices/GetTrainingOrganizationInvoiceById',
      {
        params: { id },
      },
    );
  }

  saveDocumentFileSection(body: any) {
    return this.http.post<any>(
      this.apiUrl +
        '/api/TrainingProgramRequest/CreateOrUpdateDocumentFilesSection',
      this.objectToFormDataConvertor.convert(body, true),
    );
  }

  createRenewApplication(id: string | number) {
    return this.http.post<any>(
      this.apiUrl +
        `/api/TrainingProgramRequest/RenewTrainingOrganizationApplication/${id}`,
      null,
    );
  }
}
