import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Assets } from '@assets/images';
import { AuditService } from '@app/dashboard/services/audit.service';
import { DataService } from '@app/dashboard/services/data.service';
import { TrainingOrganizationInvoiceStatusEnum } from '@app/dashboard/types/invoiceStatus';
import { Status } from '@app/dashboard/types/status';
import { LoadingService } from '@app/services/loading.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrl: './payment-details.component.scss',
})
export class PaymentDetailsComponent implements OnInit {
  paymentData: any;
  organizationName: string;
  image: string;
  imageSrc: string = '';
  imagesArr = [];
  file = Assets.pdf;
  fileData: any;
  reportId: string;
  closeImg = Assets.closeImg;
  organizationId: number;
  showSubmitModal: boolean = false;
  submittedSuccessfully: boolean = false;

  constructor(
    private dataService: DataService,
    private route: ActivatedRoute,
    private router: Router,
    private auditService: AuditService,
    private loadingService: LoadingService,
  ) {}

  isLoading$: Observable<boolean> = this.loadingService.isLoading$();

  getImageSrc(image: string): void {
    this.imageSrc = image;
  }

  getImagesArr(arr: any) {
    this.imagesArr = arr;
  }

  removeImage(index: number) {
    this.imagesArr.splice(index, 1);
  }

  handleFileData(file: any) {
    this.fileData = file;
  }

  levels = [
    {
      name: 'dashboard',
      path: '/dashboard',
    },
    {
      name: 'paymentDetails',
      path: '',
    },
  ];

  ngOnInit(): void {
    this.reportId = this.route.snapshot.queryParams['reportId'];
    this.organizationId = this.route.snapshot.params['id'];
    this.getData();
  }

  getData() {
    this.loadingService.startLoading();
    this.auditService
      .getApplicationData(this.organizationId)
      .subscribe((response) => {
        this.organizationName = response.data.trainingProgram.nameAr;
        if (
          response.data.workFlowTrainingOrganization
            .trainingOrganizationStatusLookupId !==
          Status.ApprovedByCommitteeSecretaryWaitingPayment
        )
          this.router.navigateByUrl('/error-page');
        this.dataService.getPaymentData().subscribe(
          (data) => {
            this.paymentData = data;
            this.loadingService.stopLoading();
          },
          (error) => {
            console.error('data failed', error);
          },
        );
      });
  }

  createInvoice() {
    const body = {
      id: 0,
      totalAmount: 11000,
      trainingOrganizationId: this.organizationId,
      trainingOrganizationInvoiceStatusLookupId:
        TrainingOrganizationInvoiceStatusEnum.WaitingReciept,
      file: this.fileData,
    };

    this.showSubmitModal = true;
    this.auditService.sendInvoiceData(body).subscribe(
      (response) => {
        if (response.data) {
          this.auditService
            .updateApplicationStatus({
              TrainingOrganizationStatusEnum:
                Status.ThePaymentWasMadeWaitingFinancialAuditor,
              TrainingOrganizationId: this.organizationId,
            })
            .subscribe({
              next: () => {
                this.submittedSuccessfully = true;
              },
              error: (error) => {
                console.log('Error sending the data', error);
              },
            });
        }
      },
      (error) => {
        console.error('data failed', error);
      },
    );
  }

  closeSubmitModal() {
    this.showSubmitModal = false;
    this.router.navigate(['/dashboard']);
  }
}
