import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrl: './rich-text-editor.component.scss',
})
export class RichTextEditorComponent {
  @Input() control: FormControl;

  @Output() textValueEmitter: EventEmitter<string> = new EventEmitter<string>();

  onTextChange($event) {
    this.textValueEmitter.emit($event.textValue);
  }
}
