<div class="application-details__container" *ngIf="!showCommitteeSecretaryNote">
  <app-custom-button
    buttonText="ملاحظات الأمين"
    buttonStyle="transperantGreenButton"
    *ngIf="showCommitteeSecretaryNoteButton"
    class="secretary-discussion-note-button"
    (click)="
      showCommitteeSecretaryNoteChange.emit(true);
      showCommitteeSecretaryNote = true
    "
  />
  <div class="training-body__data">
    <div class="tab-data" *ngIf="trainingOrganization">
      <app-training-body-data [trainingOrganization]="trainingOrganization" />
    </div>
    <app-custom-button
      (click)="showData()"
      *ngIf="
        !isClicked &&
        !requestIsReviewedByInternalAuditor &&
        !requestIsAddressedByTrainingOrganization &&
        !requestIsReturnedByCommitteeSecratary
      "
      [buttonStyle]="'greenButton'"
      [buttonText]="'التقييم الأولي'"
      [customClass]="'add-criteria__btn'"
    />
    <app-document-inspector
      #inspectorComponent
      *ngIf="trainingProgram"
      [isCheckbox]="
        !requestIsReviewedByInternalAuditor ||
        requestIsReturnedByCommitteeSecratary ||
        requestIsReturnedByTechnicalAdvisor
      "
      [openModal]="openModal"
      [fileIndex]="fileIndex"
      [fileURL]="fileURL"
      (closeModal)="hideModal()"
      (showTrainingDataChange)="onShowTrainingDataChange($event)"
      (showPledgeChange)="onShowPledge($event)"
      [customClass]="'initial-document'"
      [generalCommitment]="generalCommitment"
      [criteriaLookup]="criteriaLookup"
      [requestStatus]="requestStatus"
      [allNotes]="allNotes"
      [trainingOrganizationId]="
        requestData.trainingProgramDocuments.trainingOrganizationId
      "
      [trainingProgramDocumentsId]="
        requestData.trainingProgramDocuments.trainingProgramDocumentsId
      "
      (showTabDataIndexValueChanged)="onTabDataIndexValueChange()"
      [updateRequestNumber]="updateRequestNumber"
    >
      <div *ngIf="showTrainingData" class="tab-data training-program__data">
        <div class="training-body__title">بيانات البرنامج التدريبي</div>
        <div class="row-data">
          <div class="training-body__info">
            <div class="field-container">
              <app-svg-icon class="field-icon" name="document-icon" />
              <div class="field-name">اسم البرنامج التدريبي</div>
            </div>
            <div class="field-value">
              {{ trainingProgram.nameAr }}
            </div>
          </div>
          <div class="training-body__info">
            <div class="field-container">
              <app-svg-icon
                class="field-icon"
                name="document-icon"
              ></app-svg-icon>
              <div class="field-name">
                اسم البرنامج التدريبي باللغة الإنجليزية
              </div>
            </div>
            <div class="field-value">
              {{ trainingProgram.nameEn }}
            </div>
          </div>
        </div>
        <div class="row-data">
          <div class="training-body__info">
            <div class="field-container">
              <app-svg-icon class="field-icon" name="note-icon"></app-svg-icon>
              <div class="field-name">نوع البرنامج التدريبي</div>
            </div>
            <div class="field-value">
              {{ trainingProgram.trainingProgramType }}
            </div>
          </div>
          <div class="training-body__info">
            <div class="field-container">
              <app-svg-icon class="field-icon" name="badge-icon"></app-svg-icon>
              <div class="field-name">مستوى البرنامج التدريبي</div>
            </div>
            <div class="field-value">
              {{ trainingProgram.programLevelName }}
            </div>
          </div>
        </div>
        <div class="row-data">
          <div class="training-body__info">
            <div class="field-container">
              <app-svg-icon class="field-icon" name="clock-icon"></app-svg-icon>
              <div class="field-name">مدة البرنامج التدريبي (بالأيام)</div>
            </div>
            <div class="field-value">
              {{ trainingProgram.trainingProgramDuration }}
            </div>
          </div>
          <div class="training-body__info">
            <div class="field-container">
              <app-svg-icon class="field-icon" name="clock-icon"></app-svg-icon>
              <div class="field-name">عدد الساعات التدريبية الإجمالية</div>
            </div>
            <div class="field-value">
              {{ trainingProgram.totalTrainingHours }}
            </div>
          </div>
        </div>
        <div class="row-data">
          <div class="training-body__info">
            <div class="field-container">
              <app-svg-icon class="field-icon" name="target-icon" />
              <div class="field-name">الهدف العام من البرنامج التدريبي</div>
            </div>
            <div class="field-value">
              {{ trainingProgram.programObjective }}
            </div>
          </div>
          <div class="training-body__info">
            <div class="field-container">
              <app-svg-icon class="field-icon" name="globe-icon"></app-svg-icon>
              <div class="field-name">لغة البرنامج التدريبي</div>
            </div>
            <div class="field-value">
              {{ trainingProgram.languageLookupName }}
            </div>
          </div>
        </div>
        <div class="row-data">
          <div class="training-body__info">
            <div class="field-container">
              <app-svg-icon class="field-icon" name="users-icon" />
              <div class="field-name">الفئة المستهدفة</div>
            </div>
            <div class="field-value">
              {{ trainingProgram.targetCategory }}
            </div>
          </div>
        </div>
        <div>
          <div class="training-body__info">
            <div class="field-container">
              <app-svg-icon class="field-icon" name="book-icon"></app-svg-icon>
              <div class="field-name">وصف البرنامج التدريبي</div>
            </div>
            <div class="field-value training-program-description">
              {{ trainingProgram.description }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-content__container tab-data"
        *ngIf="generalCommitment && showPledge"
      >
        <div class="training-body__title">التعهد العام</div>

        <div class="pledge-container">
          <span class="pledge-intro"
            >السادة المركز السعودي لكفاءة الطاقة حفظهم االله
          </span>
          <p class="pledge-intro">السلام عليكم ورحمة االله وبركاته</p>
          <p class="pledge-para">
            نتعهد نحن {{ trainingOrganization.organizationName }}، بالالتزام
            بلائحة اعتماد البرامج التدريبية في مجال كفاءة الطاقة والدليل
            الإرشادي للائحة وكافة التعاميم والتعليمات الخاصة التي يصدرها المركز
            السعودي لكفاءة الطاقة بهذا الشأن.
          </p>
          <span class="pledge-para salute">وتقبلوا وافر التحية والتقدير</span>
          <div class="applicant-container">
            <span class="applicant-name__text">اسم مقدم الطلب</span>
            <span class="applicant-name">{{
              generalCommitment.applicantsName
            }}</span>
          </div>
          <div class="electronic-signature__container" *ngIf="imgUrl">
            <span>التوقيع الإلكتروني</span>
            <div class="electronic-signature">
              <div class="signature-container">
                <img
                  [src]="imgUrl"
                  alt="Electronic Signature"
                  class="signature-img"
                />
              </div>
            </div>
          </div>
          <div
            *ngIf="
              isEditableApplication &&
              (filterCriteria(
                requestData?.generalCommitment
                  ?.trainingProgramDocumentAndCriteriaNotes
                  ?.programDocumentCriteriaMappings
              )?.length ||
                requestData?.generalCommitment
                  ?.trainingProgramDocumentAndCriteriaNotes?.documentNotes
                  ?.length)
            "
            class="accordions-container"
          >
            <accordion
              *ngIf="
                filterCriteria(
                  requestData?.generalCommitment
                    ?.trainingProgramDocumentAndCriteriaNotes
                    ?.programDocumentCriteriaMappings
                )?.length
              "
              [customClass]="'criteria-accordion'"
              [header]="'معايير التعهد العام'"
            >
              <div class="checked-section">
                <app-view-evaluation
                  [filterAccordionCriteria]="
                    filterCriteria(
                      requestData?.generalCommitment
                        ?.trainingProgramDocumentAndCriteriaNotes
                        ?.programDocumentCriteriaMappings
                    )
                  "
                />
              </div>
            </accordion>
            <accordion
              [customClass]="'notes-accordion'"
              *ngIf="
                requestData?.generalCommitment
                  ?.trainingProgramDocumentAndCriteriaNotes?.documentNotes
                  ?.length
              "
              [header]="'ملاحظات أخرى'"
            >
              <div class="user-notes">
                <div
                  *ngFor="
                    let note of requestData?.generalCommitment
                      ?.trainingProgramDocumentAndCriteriaNotes?.documentNotes
                  "
                  class="user-note"
                  [class.empty]="!note.note"
                >
                  <app-comment-section [data]="note" />
                </div>
              </div>
            </accordion>
          </div>
        </div>
      </div>
    </app-document-inspector>
    <div
      class="initial-evaluation__container"
      *ngIf="
        isClicked ||
        requestIsReviewedByInternalAuditor ||
        requestIsAddressedByTrainingOrganization ||
        requestIsReturnedByCommitteeSecratary ||
        requestIsReturnedByTechnicalAdvisor
      "
    >
      <app-svg-icon name="long-two-sided-arrow" class="two-sided-arrow" />
      <div class="training-body__title">التقييم الأولي</div>

      <div class="files-container">
        <div
          class="documents-container"
          *ngFor="
            let data of documentInspectorStateService.trainingProgramDocumentMapping;
            let tabIndex = index
          "
        >
          <div class="file-item">
            <div class="field-download-container">
              <div class="field-item-container">
                <div class="field-title">
                  {{ data.programDocumentLookup.name }}
                </div>
                <ul class="field-subtitles">
                  <li
                    *ngFor="
                      let subtitle of data.programDocumentLookup
                        .programDocumentRequirements
                    "
                  >
                    {{ subtitle }}
                  </li>
                </ul>
              </div>
              <div class="files-field-buttons-container">
                <archived-files-dialog
                  [tabIndex]="tabIndex"
                  [allArchivedFiles]="allArchivedFiles"
                  [trainingProgramDocumentsTabIndex]="4"
                  [(visible)]="archivedFilesDialogOpen"
                />
                <file-download *ngIf="data.files.length" [files]="data.files" />
              </div>
            </div>
            <div class="active-tab">
              <div
                *ngFor="let tab of data.files; let i = index"
                class="file-item__container"
                (click)="viewFile(tabIndex, i ?? 0)"
              >
                <div class="compare-files-container">
                  <div class="file-item__container">
                    <app-svg-icon name="file-icon" class="file-icon" />

                    <div class="file-info">
                      <span class="file-name">{{
                        tab?.archivedFiles?.at(-1)?.file?.fileName
                      }}</span>
                      <span class="file-size">{{
                        tab?.archivedFiles?.at(-1)?.file?.fileSize
                      }}</span>
                    </div>
                  </div>

                  <div
                    class="file-item__container"
                    *ngIf="tab?.archivedFiles?.at(-2)?.file"
                  >
                    <app-svg-icon
                      *ngIf="tab?.archivedFiles?.at(-2)?.file?.fileName"
                      name="file-icon"
                      class="shaded-file-icon file-icon"
                    />
                    <div class="file-info">
                      <span class="file-name">{{
                        tab?.archivedFiles?.at(-2)?.file?.fileName
                      }}</span>
                      <span class="file-size">{{
                        tab?.archivedFiles?.at(-2)?.file?.fileSize
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <file-icon
                *ngIf="!data.files.length"
                [file]="{
                  name: data.programDocumentLookup.name,
                  size: 10000
                }"
                (click)="viewFile(tabIndex, i ?? 0)"
                [readOnly]="true"
              />
            </div>
          </div>
          <div class="accordions-container">
            <accordion
              *ngIf="
                accordionVisibilityHandler(
                  data.programDocumentLookup.programDocumentCriteriaMappings
                )
              "
              [customClass]="'criteria-accordion'"
              [header]="
                'معايير ' +
                data.programDocumentLookup.name +
                (accordionVisibilityHandler(
                  data.programDocumentLookup.programDocumentCriteriaMappings,
                  true
                )
                  ? ' (المدقق الداخلي)'
                  : '')
              "
            >
              <app-view-evaluation
                [filterAccordionCriteria]="
                  filterAccordionCriterias(
                    data.programDocumentLookup.programDocumentCriteriaMappings
                  )
                "
              />
            </accordion>
            <accordion
              *ngIf="
                accordionVisibilityHandler(
                  data.programDocumentLookup.programDocumentCriteriaMappings,
                  true
                )
              "
              [customClass]="'technical-advisor-evaluation criteria-accordion'"
              [header]="
                'معايير ' + data.programDocumentLookup.name + ' (الخبير الفني)'
              "
            >
              <app-view-evaluation
                [filterAccordionCriteria]="
                  filterAccordionCriterias(
                    data.programDocumentLookup.programDocumentCriteriaMappings,
                    true
                  )
                "
              />
            </accordion>
            <accordion
              [customClass]="'notes-accordion'"
              [header]="'ملاحظات أخرى'"
              *ngIf="data.documentNotes.length"
            >
              <div class="user-notes">
                <div
                  *ngFor="let note of data.documentNotes; let noteIndex = index"
                  class="user-note"
                  [class.empty]="!note.note"
                >
                  <app-comment-section [data]="note" />
                </div>

                <div *ngIf="criteria">
                  <div
                    class="user-note"
                    *ngFor="let note of criteria.body.documentNotes"
                  >
                    <div class="user-image">
                      <img src="assets/images/user-image.png" alt="" />
                    </div>
                    <div class="user-role">
                      <div class="note">
                        {{ note.note }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </accordion>
          </div>
        </div>
      </div>
      <app-field-visit-wrapper
        *ngIf="fieldVisitData.length"
        [requestStatus]="requestStatus"
        [fieldVisitData]="fieldVisitData"
      />
    </div>
    <div
      class="general-notes"
      *ngIf="
        isClicked ||
        requestIsReviewedByInternalAuditor ||
        requestIsAddressedByTrainingOrganization
      "
    >
      <div
        class="checkbox-wrapper"
        *ngIf="
          !requestIsReviewedByInternalAuditor ||
          requestIsReturnedByCommitteeSecratary ||
          requestIsReturnedByTechnicalAdvisor
        "
      >
        <input
          id="checkbox"
          type="checkbox"
          (change)="handleCheckboxChange()"
          [checked]="isChecked"
        />
        <label for="checkbox" class="checkbox-label">ملاحظات عامة</label>
      </div>
      <app-rich-text-editor
        *ngIf="
          (!requestIsReviewedByInternalAuditor ||
            requestIsReturnedByCommitteeSecratary ||
            requestIsReturnedByTechnicalAdvisor) &&
          isChecked
        "
        [control]="generalNotes"
        (textValueEmitter)="generalNotesText = $event"
      />
    </div>
    <div
      class="buttons-container"
      *ngIf="
        isClicked ||
        requestIsReviewedByInternalAuditor ||
        requestIsAddressedByTrainingOrganization
      "
    >
      <div class="inner-container">
        <app-custom-button
          [disabled]="
            (isChecked && !generalNotesText) ||
            (requestIsReviewedByInternalAuditor &&
              !requestIsReturnedByCommitteeSecratary &&
              !requestIsReturnedByTechnicalAdvisor) ||
            !isAllCriteriaEvaluated() ||
            sendToTrainingOrgButtonIsClicked
          "
          [buttonStyle]="'greenButton'"
          [buttonText]="'إرسال الطلب إلى أمين اللجنة'"
          (click)="technicalAdvisorModal()"
        />
        <app-custom-button
          [disabled]="
            !isAllCriteriaEvaluated() ||
            openAdvisorModal ||
            (isChecked && !generalNotesText)
          "
          [buttonStyle]="'transperantGreenButton'"
          [buttonText]="'إعادة الطلب إلى الجهة التدريبية'"
          (click)="showConfirmationModal()"
        />
      </div>
      <span *ngIf="!isAllCriteriaEvaluated()" class="error-msg">
        لم تقم بإكمال عملية التقييم
      </span>
      <app-dialog
        [noteId]="internalAuditorLastNote?.id"
        [id]="requestData?.trainingOrganization?.trainingOrganizationId"
        [generalNotes]="isChecked && generalNotes.value"
        [trainingOrganizationStatusEnum]="1"
        [visible]="openAdvisorModal"
        (onHide)="onDialogHide($event)"
        [isChecked]="isChecked"
        (onSuccess)="handleSuccess($event)"
        [proposedTechnicalAdvisor]="requestData?.proposedTechnicalAdvisor?.user"
        [proposedWorkingHours]="
          requestData?.proposedTechnicalAdvisor?.workingHours
        "
      />
      <div class="success-modal__container">
        <app-modal
          [modalContent]="'submission'"
          [showModal]="showSubmitModal"
          (closeModal)="
            submittedSuccessfully ? closeModel() : cancelReturnToTrainingOrg()
          "
          [customClass]="'success-modal'"
          [isCloseVisible]="!(isLoading | async)"
        >
          <loading-spinner>جاري إرسال الطلب...</loading-spinner>

          <div
            *ngIf="
              submittedSuccessfully && !(isLoading | async);
              else confirmationModal
            "
            class="modal-body"
          >
            <app-svg-icon class="success-icon" name="success-icon" />
            <span class="modal-text">{{
              sendToTrainingOrgButtonIsClicked
                ? 'تم إرسال الطلب إلى الجهة التدريبية بنجاح'
                : 'تم إرسال الطلب إلى أمين اللجنة بنجاح'
            }}</span>
          </div>
          <ng-template #confirmationModal>
            <div class="confirmation-modal-body" *ngIf="!(isLoading | async)">
              <span class="modal-text">
                هل ترغب في تأكيد إعادة إرسال الطلب إلى الجهة التدريبية؟
              </span>
              <div class="confirmation-buttons">
                <app-custom-button
                  (click)="sendApplicationToTrainingOrg()"
                  [buttonText]="'نعم، تأكيد'"
                  [buttonStyle]="'greenButton'"
                ></app-custom-button>
                <app-custom-button
                  (click)="cancelReturnToTrainingOrg()"
                  [buttonText]="'إلغاء'"
                  [buttonStyle]="'transperantGreenButton'"
                ></app-custom-button>
              </div>
            </div>
          </ng-template>
        </app-modal>
      </div>
    </div>
  </div>
</div>
<section id="secretary-note-container" *ngIf="showCommitteeSecretaryNote">
  <app-page-title customClass="secretary-note-title"
    >ملاحظات الأمين على الطلب رقم
    <span class="green-color">{{
      requestData.trainingOrganization.reportId
    }}</span></app-page-title
  >
  <article
    class="secretary-note"
    [innerHTML]="committeeSecretaryDiscussionNote?.note ?? noNotesHTML"
  ></article>
  <app-custom-button
    buttonText="العودة إلى تفاصيل الطلب"
    buttonStyle="greenButton"
    (click)="hideCommitteeSecretaryNote()"
  />
</section>
