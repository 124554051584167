import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { UserRole } from '@app/types/roles';
import { Assets } from '@assets/images/index';

@Component({
  selector: 'app-comment-section',
  templateUrl: './comment-section.component.html',
  styleUrls: ['./comment-section.component.scss'],
})
export class CommentSectionComponent implements OnInit {
  images = {
    checked: Assets.checkMark,
    clock: Assets.clock,
    altImage: Assets.altImage,
    calendar: Assets.calenadar,
  };

  @Input() showUserName: boolean = true;
  @Input() data: any = {};
  commentTime: string;
  commentDate: string;
  trainingOrganizationRoleId = UserRole.TrainingOrganization;
  nativeElement = this.elementRef.nativeElement;

  constructor(public elementRef: ElementRef) {}

  ngOnInit(): void {
    this.commentTime = this.formatDateTime(this.data.modificationDate).time;
    this.commentDate = this.formatDateTime(this.data.modificationDate).date;
  }

  formatDateTime(dateTimeString: string): { date: string; time: string } {
    const dateTime = dateTimeString ? new Date(dateTimeString) : new Date();

    if (!dateTime.getDate()) {
      const [date, time] = dateTimeString.split(' ');
      return { date, time };
    }

    const hours = dateTime.getHours();
    const minutes = dateTime.getMinutes();
    const formattedTime = `${hours % 12 || 12}:${
      minutes < 10 ? '0' : ''
    }${minutes} ${hours >= 12 ? 'PM' : 'AM'}`;

    const year = dateTime.getFullYear();
    const month = dateTime.getMonth() + 1;
    const day = dateTime.getDate();
    const formattedDate = `${day}/${month}/${year}`;

    return { date: formattedDate, time: formattedTime };
  }
}
