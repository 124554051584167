<div class="expert-comment">
  <img [src]="images.altImage" class="comment-img" />
  <div>
    <div class="comment-flex">
      <app-page-title
        [title]="data?.user?.userName"
        [customClass]="['comment-title']"
        *ngIf="showUserName || data?.user.roleId === trainingOrganizationRoleId"
      />
      <p
        class="cool-gray menu-text"
        *ngIf="showUserName && data?.user.roleId !== trainingOrganizationRoleId"
      >
        ( {{ data?.user?.role }})
      </p>

      <app-page-title
        [title]="data?.user?.role"
        [customClass]="['comment-title']"
        *ngIf="
          !showUserName && data?.user.roleId !== trainingOrganizationRoleId
        "
      />

      <img [src]="images.calendar" />
      <p class="small-font margin">
        {{ commentDate }}
      </p>
      <img [src]="images.clock" />

      <p class="small-font margin time">
        {{ commentTime }}
      </p>
    </div>
    <p *ngIf="data?.note" class="comment-style">
      {{ data?.note }}
    </p>
    <p *ngIf="data?.name" class="comment-style">{{ data.name }}</p>
  </div>
</div>
